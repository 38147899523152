import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';
import { getAssetParent, getRoomWithBuilding } from './compositeDataProvider'

const defaultApi = ( process.env.NODE_ENV !== 'production' ?
        'http://localhost:5000' : 'https://api.soundsensing.no'  );
export const apiUrl = process.env.REACT_APP_API_URL || defaultApi ;
const apiPrefix = `${apiUrl}/v1`;

const localApiTokenKey = 'soundsensing-api-token';

export const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem(localApiTokenKey);
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
}

export const getImageBlob = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({});
    }
    const token = localStorage.getItem(localApiTokenKey);
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetch(url, options)
    .then(res => res.blob())
    .then(blob => URL.createObjectURL(blob))
}

const leftTrim = function(s) {
    return s.replace(/^\s+/,"");
}

function transformObject(obj) {
    Object.keys(obj).map((key, index) => {
        let val = obj[key];
        //console.log(val, key);
        if (key === 'created_at') {
            val = new Date(val * 1000);
        }
        if (key === 'timestamp' && val != null) {
            val = new Date(val * 1000);
        }
        if (key === 'resolution_time' && val != null) {
            val = new Date(val * 1000);
        }
        if (key === 'registered_at') {
            val = new Date(val * 1000);
        }
        if (key === 'start_time') {
            val = new Date(val * 1000);
        }
        if (key === 'end_time' && val != null) {
            val = new Date(val * 1000);
        }
        if (key === 'download_url') {
            val = `${apiUrl}/${leftTrim(val)}`
        }
        obj[key] = val;
    })
    return obj;
}

function transformList(arr) {
    arr.map(enrichDataFromAPI)
    return arr;
}

function isObject(obj) {
  return obj === Object(obj);
}

function enrichDataFromAPI(data) {

    // Enrich Organization endpoint
    if (Array.isArray(data.users)) {
        data['user_ids'] = data.users.map((item) => item.id);
    }
    if (Array.isArray(data.devices)) {
        data['device_ids'] = data.devices.map((item) => item.id);
    }

    if (Array.isArray(data)) {
        return transformList(data);
    } else if (isObject(data)) {
        return transformObject(data);
    } else {
        return data;
    }
}

function normalizeDataToAPI(data) {
    // Inverse of enrichDataFromAPI()

    // Remove added properties that we have enriched with
    // Important to not trigger validation errors due to extranous properties
    if (Array.isArray(data.user_ids)) {
        delete data.user_ids;
    }
    if (Array.isArray(data.device_ids)) {
        delete data.device_ids;
    }

    // NOTE: does not recurse at the moment, or map format of values
    // Should possibly do this, to be more symmetrical with enrichDataFromAPI

    return data;
}

function transformResourcePath(name) {
    if (name === 'asset_categories') {
        return name
    }
    else {
        return name.replace('_', '/');
    }
}

// Ref https://stackoverflow.com/a/65883097
export function toFlatPropertyMap(obj, keySeparator = '.') {
  const flattenRecursive = (obj, parentProperty, propertyMap = {}) => {
    for(const [key, value] of Object.entries(obj)){
      const property = parentProperty ? `${parentProperty}${keySeparator}${key}` : key;
      if(value && typeof value === 'object'){
        flattenRecursive(value, property, propertyMap);
      } else {
        propertyMap[property] = value;
      }
    }
    return propertyMap;
  };
  return flattenRecursive(obj);
}

// Return true if any value of @obj includes @query
// matching is case-insensitive
function objectMatchesText(obj, query) {
    query = query.toLowerCase();
    
    let matches = false;
    const properties = toFlatPropertyMap(obj);
    for (const property in properties) {
        const value = properties[property];
        if (value) {
            const str = value.toString().toLowerCase();
            if (str.includes(query)) {
                matches = true;
            }
        }

    }
    return matches;
}

function objectMatchesProperty(obj, property, values) {
    // normalize single value to an array
    if (!Array.isArray(values)) {
        values = [ values ];
    }

    let matches = false;
    for (const query of values) {
        const value = obj[property];
        if (value == query) {
            matches = true;
        }
    }   

    //console.log('search-property', property, values, obj[property], matches);
    return matches;
}

// client-side implementation of freetext search
// returns filtered subset
function searchDataText(data, query) {
    //console.log('search-data', query, data);    

    query = query.trim(); // ignore leading and trailing whitespace
    if (!query) {
        // Empty query -> match all
        return data;
    }
    
    data = data.filter(o => objectMatchesText(o, query));

    return data;
}

/* client-side implementations of filters */
function filterData(data, filter) {

    // Support free-text filtering/search
    if (filter && filter.q) {
        data = searchDataText(data, filter.q);
    }
    delete filter.q;

    // Support filtering by specific property
    for (const property in filter) {
        const filterValues = filter[property];
        data = data.filter(o => objectMatchesProperty(o, property, filterValues));
    }

    return data;
}

export async function getListDefault(resource, params, queryOptions = {}){
    let { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        if (Object.keys(params.filter).length) {
            console.log("warning, filter enabled, using client-side filtering and overriding page size");
            perPage = 10000; // HACK, to allow client-side filtering to work
        }

        const defaultQuery = {
            limit: perPage,
            offset: (page - 1) * perPage,
            sort: field,
            order: order
            //filter: JSON.stringify(params.filter),
        };
        const query = Object.assign(defaultQuery, queryOptions);
        resource = transformResourcePath(resource);
        const url = `${apiPrefix}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => {
            let data = enrichDataFromAPI(json.data);
            data = filterData(data, params.filter);

            // If no pagination from api endpoint
            // slice locally like we used to to make pagination still work in frontend
            if(!json.pagination){
                console.warn('No pagination supplied from endpoint', url)
                let startIdx = (page - 1) * perPage;
                let endIdx = page * perPage - 1;
                data = data.slice(startIdx, endIdx)
            }
            return {
                data: data,
                total: json.pagination ? json.pagination.total : json.data.length,
            };
        });
}

export default {
    getList: (resource, params) => {
        if(resource === "assets"){
            let promise = getAssetParent(params)
            return promise
        }
        if(resource === "rooms"){
            let promise = getRoomWithBuilding(params)
            return promise
        }
        else {
            let query = {}
            if (resource == "ml/templates") {
                query = { "include_devices": 0 }
            }
            if(resource === "alarm") {
                if (Object.keys(params.filter).includes("organization_id")) {
                    query = {"organization_id": params.filter["organization_id"]}
                    delete params.filter["organization_id"]
                }
            }
            let promise = getListDefault(resource, params, query)
            return promise
        }
    },

    getOne: (resource, params) => {
        resource = transformResourcePath(resource);
        return httpClient(`${apiPrefix}/${resource}/${params.id}`).then(({ json }) => ({
            data: enrichDataFromAPI(json.data),
        }))
    },

    getMany: (resource, params) => {
        resource = transformResourcePath(resource);
        const query = {
            //filter: JSON.stringify({ id: params.ids }), // not supported
        };
        const url = `${apiPrefix}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({ json }) => ({ data: enrichDataFromAPI(json.data) }));
    },

    getManyReference: (resource, params) => {
        resource = transformResourcePath(resource);
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            limit: perPage,
            offset: (page - 1) * perPage,
            sort: field,
            order: order
            //filter: JSON.stringify(params.filter),
        };
        // reference queries should match data based on target=id
        // Normalize to be like any other filter
        let filter = Object.assign({}, params.filter);
        if (params.id && params.target) {
            filter[params.target] = params.id;
        }

        const url = `${apiPrefix}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => {
            let data = enrichDataFromAPI(json.data);
            data = filterData(data, filter);

            // If no pagination from api endpoint
            // slice locally like we used to to make pagination still work in frontend
            if(!json.pagination){
                console.warn('No pagination supplied from endpoint', url)
                let startIdx = (page - 1) * perPage;
                let endIdx = page * perPage - 1;
                data = data.slice(startIdx, endIdx)
            }
            return {
                data: data,
                total: json.pagination ? json.pagination.total : json.data.length,
            };
        });
    },

    update: (resource, params) => {
        console.log('update', resource, params.data);

        params.data = normalizeDataToAPI(params.data);

        // Date values will be converted to datestrings by default
        // Convert to timestamp instead.
        for (const [key, value] of Object.entries(params.data)) {
            if (value instanceof Date){
                params.data[key] = value.getTime() / 1000
            }
        }

        resource = transformResourcePath(resource);
        return httpClient(`${apiPrefix}/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => json)
    },

    updateMany: (resource, params) => {
        resource = transformResourcePath(resource);
        params.data = normalizeDataToAPI(params.data);

        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };
        return httpClient(`${apiPrefix}/${resource}?${stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },

    create: (resource, params) => {
        resource = transformResourcePath(resource);

        //Date values will be converted to datestrings, convert to timestamp instead.
        for (const [key, value] of Object.entries(params.data)) {
            if (value instanceof Date){
                params.data[key] = value.getTime() / 1000
            }
        }

        return httpClient(`${apiPrefix}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id },
        }))
    },

    delete: (resource, params) => {
        resource = transformResourcePath(resource);
        return httpClient(`${apiPrefix}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json }))
    },

    deleteMany: (resource, params) => {
        resource = transformResourcePath(resource);
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };
        return httpClient(`${apiPrefix}/${resource}?${stringify(query)}`, {
            method: 'DELETE',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    }
};
