
import React from 'react';
import {useState, useEffect} from 'react';

import {
    List,
    Datagrid,
    TextField,
    NumberField,
    ReferenceField,
    AutocompleteInput,
    FunctionField,
    DateField,
    Edit,
    Create,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    SearchInput,
    NumberInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    ReferenceManyField,
    SingleFieldList,
    ChipField,
    SimpleFormIterator,
    DateTimeInput,
    DateInput,
    ArrayInput,
    ArrayField,
    EmailField,
    useRecordContext,
    BooleanInput,
    BooleanField, NullableBooleanInput,
} from 'react-admin';

import {
    StandardPagination
} from '../common.js'


const listFilters = [
    <ReferenceArrayInput source="device_id" reference="devices" label="Devices" perPage={10000} alwaysOn>
        <AutocompleteArrayInput optionText="serial"/>
    </ReferenceArrayInput>,

    <ReferenceArrayInput source="creator_id" reference="users" label="Creator" perPage={10000} alwaysOn>
        <AutocompleteArrayInput optionText="name"/>
    </ReferenceArrayInput>,

    <NullableBooleanInput source="customer_visible" label="Customer Visible" defaultValue={null} alwaysOn/>,
    <NullableBooleanInput source="resolved" label="Resolved" defaultValue={null} alwaysOn/>,

    <ReferenceArrayInput source="organization_id" reference="organizations" label="Organization" perPage={10000} alwaysOn inQuery>
        <AutocompleteArrayInput optionText="name"/>
    </ReferenceArrayInput>,

    <SearchInput source="q" alwaysOn/>,
]

const SubReference = ({ translateChoice, children, ...props }) => (
    <ReferenceField {...props}>{children}</ReferenceField>
);

export const AlarmList = props => (
    <List pagination={<StandardPagination />} filters={listFilters} perPage={25} {...props}>
        <Datagrid rowClick="edit">
            <ReferenceField source="device_id" reference="devices"><TextField source="serial"/></ReferenceField>
            <ReferenceField source="device_id" reference="devices" label="Organization">
                <SubReference source="organization_id" reference="organizations">
                    <TextField source="name"/>
                </SubReference>
            </ReferenceField>
            <DateField source="timestamp" showTime label="Alarm time"/>
            <DateField source="created_at" showTime/>
            <ReferenceField label="Creator" source="creator_id" reference="users"><TextField source="name"/></ReferenceField>
            <TextField source="alarm_type"/>
            <TextField source="description"/>
            <BooleanField source="resolved"/>
            <BooleanField source="customer_visible"/>
        </Datagrid>
    </List>
);

export const AlarmEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled label="Alarm id" source="id"/>
            <ReferenceField source="device_id" reference="devices"><TextField source="serial"/></ReferenceField>
            <DateField source="timestamp" showTime label="Alarm time"/>
            <DateInput disabled source="created_at" label='Created'/>
            <ReferenceField label="Creator" source="creator_id" reference="users"><TextField source="name"/></ReferenceField>
            <TextInput source="alarm_type"/>
            <BooleanInput source="resolved"/>
            <TextInput source="resolution_description"/>
            <DateInput disabled source="resolution_time" label='Resolved at'/>
            <TextInput source="description"/>
        </SimpleForm>
    </Edit>
);
export const AlarmCreate = props => {
    const [selectedOrganizationId, setSelectedOrganizationId] = useState("");

    const handleOrganizationChange = event => {
      setSelectedOrganizationId(event.target.value);
    };
    // transform: remove organization_id from the data before sending it to the API
    const transform = data => {
        const { organization_id, ...rest } = data;
        return rest;
    };
    return (
        <Create {...props} transform={transform}>
            <SimpleForm>
            <ReferenceInput
                label="Organization"
                reference="organizations"
                perPage={1000}
                source="organization_id"
                onChange={handleOrganizationChange}>
                <SelectInput optionText="name" optionValue="id" />
            </ReferenceInput>
            <ReferenceInput
                label="Device"
                reference="devices"
                source="device_id"
                perPage={10000}
                filter={{ organization_id: selectedOrganizationId }}>
                <AutocompleteInput optionText="serial" optionValue="id" />
            </ReferenceInput>
            <TextInput source="description" />
            <DateTimeInput source="timestamp" />
            <SelectInput
                source="alarm_type"
                choices={[{ id: "A", name: "A" }, { id: "B", name: "B" }]}
            />
            </SimpleForm>
      </Create>
    );
};
