

import React from 'react';

import {
    List,
    Datagrid,
    TextField,
    NumberField,
    ReferenceField,
    AutocompleteInput,
    FunctionField,
    DateField,
    Edit,
    Create,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    SearchInput,
    NumberInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    ReferenceManyField,
    SingleFieldList,
    ChipField,
    SimpleFormIterator,
    DateTimeInput,
    DateInput,
    ArrayInput,
    ArrayField,
    EmailField,
    useRecordContext, minLength, PasswordInput,
} from 'react-admin';

import {
    StandardPagination
} from '../common.js'


// FIXME: support filtering on organization
const listFilters = [
    <SearchInput source="q" alwaysOn/>,
]

// TODO: list organizations
export const UserList = props => (
    <List pagination={<StandardPagination />} filters={listFilters} perPage={25} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="name"/>
            <EmailField source="email"/>
        </Datagrid>
    </List>
);

const transform = data => {
    if (['', ' ', null, undefined].includes(data['new_password'])){
        delete data['new_password']
    }
    else {
        data['password'] = data['new_password']
        delete data['new_password']
    }
    return data
}

export const UserEdit = (props) => (
    <Edit {...props} transform={transform}>
        <SimpleForm>
            <TextField source="id"/>
            <TextInput source="name"/>
            <TextInput source="email"/>
            <PasswordInput source="new_password"/>
        </SimpleForm>
    </Edit>
);

export const UserCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput disabled label="Id" source="id"/>
            <TextInput source="name"/>
            <TextInput source="email"/>
            <TextInput source="password"/>
        </SimpleForm>
    </Create>
);
